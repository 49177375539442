import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarFormStyles = makeStyles((theme: any) => createStyles({
  calendarFormHolder: {
    borderRadius: 10,
    display: "inline-block",
    position: "relative",
    float: "left",
    background: theme.palette.intakePaper.main,
    width: 560,
    height: 485,
    top: 75,
    marginBottom: 150,
    [theme.breakpoints.down('sm')]: {
      width: 410,
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      top: 50,
      height: 450,
      marginBottom: 0,
    },
    '@media (max-width: 529.95px)': {
      height: 420,
    }
  },
  bookingsHolder: {
    borderRadius: 10,
    display: "inline-block",
    position: "relative",
    float: "right",
    textAlign: "center",
    background: theme.palette.intakePaper.main,
    width: 371,
    minHeight: 320,
    marginBottom: 150,
    height: "auto",
    top: 75,
    [theme.breakpoints.down('sm')]: {
      width: 270,
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
      top: 70,
      minHeight: 400,
      marginBottom: 120,
    }
  },
  calendarHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: 24,
    position: "absolute",
    top: 38,
    left: 41,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      left: 30,
    },
    [theme.breakpoints.down('xs')]: {
      top: 25,
    },
    '@media (max-width: 529.95px)': {
      fontSize: 20,
      left: 30,
      top: 25,
    },
    '@media (max-width: 419.95px)': {
      left: 25,
    },
    '@media (max-width: 384.95px)': {
      left: 15,
      top: 20,
    }
  },
  calendarWrapper: {
    overflow: "hidden",
    backgroundColor: theme.palette.intakePaper.main
  },
  calendar: {
    width: 450,
  },
  leftArrow: {
    left: 400,
    position: "relative",
    backgroundColor: theme.palette.intakePaper.main,
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      left: 240,
    },
    [theme.breakpoints.down('xs')]: {
      left: 300,
    },
    '@media (max-width: 529.95px)': {
      left: 250,
    },
    '@media (max-width: 419.95px)': {
      left: 220,
    },
    '@media (max-width: 384.95px)': {
      left: 180,
    }
  },
  rightArrow: {
    position: "relative",
    right: 10,
    backgroundColor: theme.palette.intakePaper.main,
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      right: 12,
    },
    [theme.breakpoints.down('xs')]: {
      right: 18,
    },
    '@media (max-width: 529.95px)': {
      right: 16,
    },
    '@media (max-width: 419.95px)': {
      right: 7,
    },
    '@media (max-width: 384.95px)': {
      right: 8,
    }
  },
  timezoneHeader: {
    fontSize: 16,
    position: "relative",
    top: 3,
   /*  left: 96,
    top: 2, */
    fontWeight: 500,
   /*  [theme.breakpoints.down('sm')]: {
      left: 60,
    },
    [theme.breakpoints.down('xs')]: {
      left: 72,
    },
    '@media (max-width: 529.95px)': {
      left: 70,
    },
    '@media (max-width: 384.95px)': {
      left: 55,
    } */
  },
  nextButton: {
    backgroundColor: "#525252",
    position: "relative",
    top: 70,
    left: 90,
    display: "inline-block",
    width: 115,
    height: 46,
    marginBottom: 110,
    color: "white",
    [theme.breakpoints.down('sm')]: {
      left: 50,
    },
    [theme.breakpoints.down('xs')]: {
      width: "86%",
      height: 70,
      backgroundColor: "#00B6FF",
      left: -4,
      top: 70,
    },
    '@media (max-width: 529.95px)': {
      width: "83.5%"
    },
    '@media (max-width: 419.95px)': {
      width: "85.5%",
      left: -1
    },
    '@media (max-width: 384.95px)': {
      width: "88%",
      left: 0,
    }
  },
  nextButtonNoBookings: {
    top: 82,
    [theme.breakpoints.down('xs')]: {
      top: 91,
    },
  },
  bookingButton: {
    display: "inline-block",
    width: 300,
    height: 60,
    fontSize: 20,
    borderWidth: "2px !important",
    marginBottom: 16,
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "97%",
      marginRight: 5,
      height: 65,
      position: "relative",
      borderRadius: 5,
    },
    '@media (max-width: 529.95px)': {
      width: "95%"
    },
    '@media (max-width: 384.95px)': {
      width: "97%"
    }
  },
  bookingButtonSelected: {
    backgroundColor: "#00B6FF",
    border: "none !important",
    color: "white",
    "&:hover": {
      backgroundColor: "#00B6FF",
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: "#FFFFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    }
  },
  timezoneIcon: {
    position: "relative",
   /*  left: 58, */
    color: theme.palette.text.main,
    marginRight: 15,
  /*   [theme.breakpoints.down('sm')]: {
      left: 22,
    },
    [theme.breakpoints.down('xs')]: {
      left: 32,
    },
    '@media (max-width: 529.95px)': {
      left: 30,
    },
    '@media (max-width: 419.95px)': {
      left: 23,
    },
    '@media (max-width: 384.95px)': {
      left: 15,
    } */
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    width: "30px !important",
    height: "30px !important",
    top: 7,
    right: 140,
    [theme.breakpoints.down('xs')]: {
      top: 20,
      right: 250,
    },
    '@media (max-width: 529.95px)': {
      right: 210,
    },
    '@media (max-width: 419.95px)': {
      right: 200,
    },
    '@media (max-width: 384.95px)': {
      right: 170,
    }
  },
  error: {
    color: "red",
    fontSize: 14,
    position: "relative",
    top: 50,
    '@media (max-width: 384.95px)': {
      display: "inline-block",
      width: 250,
    }
  }
})
)

export { CalendarFormStyles };