import { makeStyles, createStyles } from '@material-ui/core/styles';

const CalendarHeaderStyles = makeStyles((theme: any) => createStyles({
  logoHolder: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    height: 145,
    width: 145,
    backgroundColor: theme.palette.intakePaper.main,
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      height: 90,
      width: 90,
      left: 0,
    }

  },
  eventWelcome: {
    fontSize: 20,
    position: "relative",
    left: 169,
    top: 65,
    fontWeight: 400,
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      left: 105,
      top: 30,
    },
    '@media (max-width: 384.95px)': {
      fontSize: 17,
    }
  },
  eventName: {
    fontSize: 30,
    position: "relative",
    top: 75,
    left: 41,
    color: "#FFB700",
    textAlign: "left",
    display: "inline-block",
    width: 705,
    fontWeight: 400,
    wordWrap: "break-word",
    [theme.breakpoints.down('sm')]: {
      width: 505,
      left: 69,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
      left: 42,
      top: 40,
      width: 375,
      marginBottom: 50,
    },
    '@media (max-width: 529.95px)': {
      left: 57,
      width: 305,
    },
    '@media (max-width: 419.95px)': {
      left: 53,
      width: 270,
    },
    '@media (max-width: 384.95px)': {
      fontSize: 24,
      width: 200,
      left: 55,
    }
  },
  eventNameError: {
    fontSize: 30,
    position: "relative",
    top: 75,
    left: 88,
    color: "#FFB700",
    textAlign: "left",
    display: "inline-block",
    width: 400,
    fontWeight: 400,
    wordWrap: "break-word",
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      left: 65,
      top: 30,
      width: 300,
    },

  },
  eventDate: {
    fontSize: 24,
    position: "relative",
    textAlign: "left",
    top: 50,
    paddingBottom: 45,
    width: 405,
    left: 167,
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      left: 135,
      top: 38,
      width: 300
    },
  },
  deadLinkErrorMessageHolder: {
    color: theme.palette.intakePaper.main,
    width: 560,
    minHeight: 80,
    textAlign: "center",
    position: "relative",
    display: "inline-block",
  },
  deadLinkErrorMessage: { 

  }
})
)

export { CalendarHeaderStyles };