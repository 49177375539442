import { makeStyles, createStyles } from '@material-ui/core/styles';

const ShowboatCalendarStyles = makeStyles((theme: any) => createStyles({
  calendarWrapper: {
    backgroundColor: theme.palette.intakeBackground.main,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    boxShadow: "none",
    textAlign: "center",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
    }
  },
  deadLinkErrorHolder: {
    width: "100%",
    position: "relative",
    display: "inline-block",
    right: 0,
    top: 75,
    minHeight: 80,
    backgroundColor: theme.palette.intakePaper.main,
    borderRadius: 10,
  },
  deadLinkError: {
    fontSize: 24,
    display: "inline-block",
    wordWrap: "break-word",
    width: 460,
    position: "relative",
    top: 20, 
    paddingBottom: 40,
    [theme.breakpoints.down('xs')]: {
      width: 360,
      top: 23,
      fontSize: 20,
    },
    '@media (max-width: 529.95px)': {
      fontSize: 17,
      top: 27,
    },
    '@media (max-width: 384.95px)': {
      right: 25,
    }
  },
})
)

export { ShowboatCalendarStyles };