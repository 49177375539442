import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import dotenv from 'dotenv';
import App from './App';
import TimeoutErrorPage from './ErrorPages/TimeoutErrorPage/TimeoutErrorPage';
import FailedLoginErrorPage from './ErrorPages/FailedLoginErrorPage/FailedLoginErrorPage';
import { ServerHelper } from './utilities/ServerHelper';


//Start up dotenv
dotenv.config();

document.title = "Showboat Calendar"

const handleConnectionTimeout = () => {

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);
 
  ReactDOM.render(
    <TimeoutErrorPage />,
    document.getElementById('root')
  )
};

ServerHelper.OnServerConnectionTimeout.Add(handleConnectionTimeout);

const handleLoginResultSuccess = (loginResult: any) => {

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
      <App primaryThemeColor={ServerHelper.ApplicationSkin.primaryThemeColor} theme={ServerHelper.ApplicationSkin.theme}/>
   ,
    document.getElementById('root')
  );

}

const handleLoginResultFailure = () => {
  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <FailedLoginErrorPage />,
    document.getElementById('root')
  )
}

ServerHelper.Login()
.then((loginResult) => {

  if (loginResult && loginResult.success) {
    handleLoginResultSuccess(loginResult);
  } else if (!loginResult.success && 
    (loginResult.failReason === "NotStarted" || loginResult.failReason === "Ended" 
    || loginResult.failReason === "Deleted calendar code." || loginResult.failReason === "CapacityFull"
    || loginResult.failReason === "RegistrationCapacityFull")) {
      handleLoginResultSuccess(loginResult); 
  } else {
    handleLoginResultFailure();
  }

})
.catch(err => {
  ReactDOM.render(
    <FailedLoginErrorPage />,
    document.getElementById('root')
  )
})

