import { makeStyles, createStyles } from '@material-ui/core/styles';

const RegistrationFormStyles = makeStyles((theme: any) => createStyles({
  bioInput: {
    width: 460,
    height: 56,
    backgroundColor: theme.palette.intakeInput.main,
    marginBottom: 37,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
    [theme.breakpoints.down('xs')]: {
      width: 425,
    },
    '@media (max-width: 529.95px)': {
      width: 325,
    },
    '@media (max-width: 419.95px)': {
      width: 310,
    },
    '@media (max-width: 384.95px)': {
      width: 265,
    }
  },
  bioNext: {
    backgroundColor: theme.palette.intakeBlue.main,
    color: "white",
    height: 46,
    width: 115,
    position: "absolute",
    right: 50,
    bottom: 54,
    [theme.breakpoints.down('sm')]: {
      right: 35,
    },
    [theme.breakpoints.down('xs')]: {
      width: "41%",
      height: 65,
      right: 37,
    },
    '@media (max-width: 529.95px)': {
      width: "38%",
    },
    '@media (max-width: 419.95px)': {
      width: "40%",
      right: 33,
    },
    '@media (max-width: 384.95px)': {
      height: 60,
      right: 20,
      width: "40%"
    }
  },
  bioBack: {
    backgroundColor: "#434343",
    color: "white",
    height: 46,
    width: 115,
    position: "absolute",
    right: 180,
    bottom: 54,
    [theme.breakpoints.down('sm')]: {
      right: 170,
    },
    [theme.breakpoints.down('xs')]: {
      width: "41%",
      height: 65,
      right: 260,
    },
    '@media (max-width: 529.95px)': {
      right: 210,
      width: "38%",
    },
    '@media (max-width: 419.95px)': {
      right: 195,
      width: "40%"
    },
    '@media (max-width: 384.95px)': {
      right: 163,
      height: 60,
      width: "40%"
    }
  },
  bioInputNotRequired: {
    width: 460,
    height: 56,
    backgroundColor: theme.palette.intakeInput.main,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      width: 330,
    },
    [theme.breakpoints.down('xs')]: {
      width: 425,
    },
    '@media (max-width: 529.95px)': {
      width: 325,
    },
    '@media (max-width: 419.95px)': {
      width: 310,
    },
    '@media (max-width: 384.95px)': {
      width: 265,
    },
  },
  errorText: {
    fontSize: 12
  },
  input: {
    height: 460,
    display: "inline-block",
  },
  loadingSpinner: {
    color: "grey",
    height: "30px !important",
    width: "30px !important",
    position: "absolute",
    right: 93,
    bottom: 110,
    [theme.breakpoints.down('sm')]: {
      right: 80,
    },
    [theme.breakpoints.down('xs')]: {
      right: 125,
      bottom: 130,
    },
    '@media (max-width: 529.95px)': {
      right: 95,
    },
    '@media (max-width: 419.95px)': {
      right: 93,
    },
    '@media (max-width: 384.95px)': {
      right: 70,
    }
  },
  allSetHeader: {
    fontSize: 24,
    position: "absolute",
    top: 39,
    left: 50,
  },
  confirmationMessage: { 
    fontSize: 24,
    position: "absolute",
    left: 50,
    bottom: 68
  },
  error: {
    color: "red",
    fontSize: 14,
    position: "relative",
    left: 50,
    top: 25,
  }
})
)

export { RegistrationFormStyles };