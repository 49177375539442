import { Paper, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ServerHelper } from '../utilities/ServerHelper';

import styles from './CalendarHeader.module.css';
import { CalendarHeaderStyles } from './CalendarHeaderStyles';

interface Props {
  
}

export default function CalendarHeader(props: Props): ReactElement {

  const classes = CalendarHeaderStyles();
  
  return (
    <div className={styles.registrationHeaderWrapper}>
      <Paper elevation={0} classes={{ root: classes.logoHolder }}>
        <img className={styles.showboatLogo} src="assets/images/sb-logo.png" alt="showboat logo" />
      </Paper>

      <Typography variant="h2" classes={{ root: classes.eventWelcome }}>
        Register for
      </Typography>
      <Typography variant="h1" classes={{ root: classes.eventName }}>
        {ServerHelper.calendar.calendarName}
      </Typography>
      
    </div>
  )
}
